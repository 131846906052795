import * as types from '@/store/mutation-types'
import i18n from '@/plugins/i18n'
import * as Validator from 'vee-validate'

const getters = {
  tick: (state) => state.tick
}

const actions = {
  playTick({ commit }, payload) {
    // commit(types.SUCCESS, null)
    // commit(types.ERROR, null)
    commit(types.PLAY_TICK, payload)
  },
  stopTick({ commit }, payload) {
    // commit(types.SUCCESS, null)
    // commit(types.ERROR, null)
    commit(types.STOP_TICK, payload)
  }
}

const mutations = {
  [types.PLAY_TICK](state, value) {
    state.tick = value
  },
  [types.STOP_TICK](state, value) {
    window.cancelAnimationFrame(state.tick)
  }
}

const state = {
  tick: null
}

export default {
  state,
  getters,
  actions,
  mutations
}

export default [
  // {
  //   path: '/codex/graphix',
  //   name: 'Graphix',
  //   meta: {
  //     requiresAuth: true
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "test" */ '@/pages/Lab/index.vue')
  // },
  {
    path: '/codex',
    name: 'Codex',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "test" */ '@/pages/Codex/index.vue')
  },
  {
    path: '/codex/:id',
    name: 'CodexList',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "test" */ '@/pages/Codex/index.vue')
  },
]
export default [
  // {
  //   path: '/home',
  //   name: 'home',
  //   meta: {
  //     requiresAuth: true
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "home" */ '@/pages/Home/index.vue')
  // },
  {
    path: '/explore',
    name: 'Explore',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "home" */ '@/pages/Explore/index.vue')
  },
  {
    path: '/inbox',
    name: 'Inbox',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "home" */ '@/pages/Inbox/index.vue')
  },
]


//./plugins/posthog.js
import posthog from "posthog-js";

export default {
    install(Vue, options) {
        Vue.prototype.$posthog = posthog.init(
            `${process.env.VUE_APP_POSTHOG_KEY}`,
            {
                api_host: `${process.env.VUE_APP_POSTHOG_API}`
            }
        );
    }
};


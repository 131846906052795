import * as types from '@/store/mutation-types'
import i18n from '@/plugins/i18n'
import * as Validator from 'vee-validate'
import Vue from 'vue'
import Vuex from 'vuex'

const getters = {
  spaceViews: (state) => state.spaceViews,
  expExe: (state) => state.expExe,
  expMeta: (state) => state.expMeta,
  expResult: (state) => state.result,
  expResearch: (state) => state.research,
  expEnd: (state) => state.end,
  expSwipeUse: (state) => state.swipeUse
}

const actions = {
  expData({ commit }, payload) {
    // commit(types.SUCCESS, null)
    // commit(types.ERROR, null)

    commit(types.EXP_DATA, payload)
  },
  objectData({ commit }, payload) {
    // commit(types.SUCCESS, null)
    // commit(types.ERROR, null)

    commit(types.OBJECT_DATA, payload)
  },
  expResearch({ commit }, payload) {
    // commit(types.SUCCESS, null)
    // commit(types.ERROR, null)

    commit(types.EXP_RESEARCH, payload)
  },
  expScore({ commit }, payload) {
    // commit(types.SUCCESS, null)
    // commit(types.ERROR, null)

    commit(types.EXP_SCORE, payload)
  },
  expExe({ commit }, payload) {
    // commit(types.SUCCESS, null)
    // commit(types.ERROR, null)

    commit(types.EXP_EXE, payload)
  },
  expSwipe({ commit }, payload) {
    // commit(types.SUCCESS, null)
    // commit(types.ERROR, null)

    commit(types.EXP_SWIPE, payload)
  },
  expTimer({ commit }, payload) {
    // commit(types.SUCCESS, null)
    // commit(types.ERROR, null)

    commit(types.EXP_TIMER, payload)
  },
  expMeta({ commit }, payload) {
    // commit(types.SUCCESS, null)
    // commit(types.ERROR, null)

    commit(types.EXP_META, payload)
  },
  expResult({ commit }, payload) {
    // commit(types.SUCCESS, null)
    // commit(types.ERROR, null)

    commit(types.EXP_RESULT, payload)
  },
  expSwipeUse({ commit }, payload) {
    // commit(types.SUCCESS, null)
    // commit(types.ERROR, null)

    commit(types.EXP_SWIPE_USE, payload)
  },
  expEnd({ commit }, payload) {
    // commit(types.SUCCESS, null)
    // commit(types.ERROR, null)

    commit(types.EXP_END, payload)
  },
  newView({ commit }, payload) {
    // commit(types.SUCCESS, null)
    // commit(types.ERROR, null)

    commit(types.NEW_VIEW, payload)
  },
  updateView({ commit }, payload) {
    // commit(types.SUCCESS, null)
    // commit(types.ERROR, null)

    commit(types.UPDATE_VIEW, payload)
  },
  clearView({ commit }, payload) {
    // commit(types.SUCCESS, null)
    // commit(types.ERROR, null)
    commit(types.CLEAR_VIEW, payload)
  },


}

const mutations = {
  [types.EXP_DATA](state, value) {
    console.log("VALUE ::: ", value)
    state.data = value
  },
  [types.OBJECT_DATA](state, value) {
    state.object = value
  },
  [types.EXP_RESEARCH](state, value) {
    state.research = value
  },
  [types.EXP_SCORE](state, value) {
    state.score = value
  },
  [types.EXP_EXE](state, value) {
    console.log("Exe RUN ~!")
    state.exe = value
  },
  [types.EXP_SWIPE](state, value) {
    state.swipe = value
  },
  [types.EXP_TIMER](state, value) {
    state.timer = value
  },
  [types.EXP_META](state, value) {
    state.meta = value
  },
  [types.EXP_RESULT](state, value) {
    state.result = value
  },
  [types.EXP_SWIPE_USE](state, value) {
    state.swipeUse = value
  },
  [types.EXP_END](state, value) {
    state.end = value
  },
  [types.NEW_VIEW](state, value) {
    state.spaceViews.push(value)

    console.log(state.spaceViews, value)

  },
  [types.UPDATE_VIEW](state, value) {
    const index = state.spaceViews.findIndex((item) => item.space === value.space);

    Vue.set(state.spaceViews, index, value);
    // state.spaceViews[value.order] = value


    console.log(state.spaceViews, value)

  },
  [types.CLEAR_VIEW](state, value) {
    state.spaceViews = []
  }

}

const state = {
  data: null,
  object: null,
  spaceViews: [],
  score: null,
  exe: 0,
  swipe: null,
  meta: null,
  result: false,
  timer: null,
  timerSec: 20,
  research: null,
  end: false,
  swipeUse: {
    next: 0,
    back: 0,
    replay: 0
  },
  exploreMode: true,
  playMode: false,
  playEnd: false,
  allExp: [],
  play: [],
  swiperIndex: 0,
  feedback: false,
  exping: false,
  expInit: false,
  gameLaoded: false,
  loadingMore: false,
  screenBaseSize: {
    maxWidth: 1920,
    maxHeight: 1080,
    minWidth: 270,
    minHeight: 480,
    width: 540,
    height: 960
  },
  stop: false,
  preload: null,
  expStart: false,
  commentSheet: false,
  shareSheet: false,
  reportSheet: false,
  reportDialog: false,
  expDataMore: null,
  dontRecDialog: true,
  report: null,
  reportData: null
}

export default {
  state,
  getters,
  actions,
  mutations
}

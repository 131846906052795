export default [


  {
    path: '/game',
    name: 'Game',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/pages/Game/index.vue')
  },
  {
    path: '/proto',
    name: 'Proto',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/pages/Proto/index.vue')
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/pages/Feedback/index.vue')
  },
  {
    path: '/thankx',
    name: 'Thankx',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/pages/Thankx/index.vue')
  },
  {
    path: '/play',
    // meta: {
    //   requiresAuth: true
    // },
    name: 'Play',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/pages/Play/index.vue')
  },
  {
    path: '/test',
    // meta: {
    //   requiresAuth: true
    // },
    name: 'Test',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/pages/Test/index.vue')
  },
  {
    path: '/three',
    // meta: {
    //   requiresAuth: true
    // },
    name: 'Three',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/pages/Three/index.vue')
  },
  {
    path: '/character',
    // meta: {
    //   requiresAuth: true
    // },
    name: 'Character',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/pages/Character/index.vue')
  },
  {
    path: '/tester',
    // meta: {
    //   requiresAuth: true
    // },
    name: 'Tester',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/pages/Tester/index.vue')
  },
  {
    path: '/room',
    meta: {
      requiresAuth: true
    },
    name: 'Room',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/pages/Room/index.vue')
  },
  {
    path: '/k/:id',
    // meta: {
    //   requiresAuth: true
    // },
    name: 'Testa',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/pages/Test/index.vue')
  },
  {
    path: '/:id',
    meta: {
      requiresAuth: true
    },
    name: 'Creator',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/pages/Creator/index.vue')
  },
  {
    path: '/mobile',
    name: 'Mobile',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/pages/Mobile/index.vue')
  },

]
<template v-slot:activator="{ on }">
  <div>
    <v-app-bar flat app elevation="0">
      <span class="hidden-md-and-up">
        <v-app-bar-nav-icon @click="sidebar = !sidebar"></v-app-bar-nav-icon>
      </span>
      <router-link :to="{ name: 'home' }" v-if="isTokenSet">
        <img class="logo" src="/klik_logo.png" alt="" />
      </router-link>

      <router-link :to="{ name: 'landing' }" v-else>
        <img class="logo" src="/klik_logo.png" alt="" />
      </router-link>
      <div class="ml-0">

        <div v-resize-text>
          <router-link :to="{ name: 'home' }" tag="span" style="cursor: pointer" v-if="isTokenSet" class="home">{{
            appTitle
          }}</router-link>
          <router-link :to="{ name: 'landing' }" tag="span" style="cursor: pointer" v-else>{{ appTitle }}</router-link>
        </div>
      </div>
      <v-spacer></v-spacer>
      <v-row v-if="isTokenSet" align="end" align-content="end" justify="end">
        <v-menu rounded="b-xl" offset-y>
          <template v-slot:activator="{ attrs, on }">
            <v-btn color="white" class="black--text ma-5" v-bind="attrs" v-on="on">
              <v-icon left>mdi-account-box</v-icon>Account
            </v-btn>
          </template>

          <v-list>
            <v-list-item>
              <v-btn text to="/profile" class="hidden-sm-and-down btnLogout">
                <v-icon left>mdi-folder-account </v-icon>
                Profile
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn text @click="userLogout" class="hidden-sm-and-down btnLogout">
                <v-icon left>mdi-exit-to-app</v-icon>
                {{ $t('menuItems.LOGOUT') }}
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-row>

      <div v-else>


        <v-btn text exact :class="['hidden-sm-and-down']">
          <localeChanger>
          </localeChanger>
        </v-btn>
        <v-btn text exact :class="['hidden-sm-and-down']" to="/hiring">
          Hiring
        </v-btn>
        <v-btn text exact :class="['hidden-sm-and-down']" to="/about">
          About
        </v-btn>
        <v-btn text exact :class="['hidden-sm-and-down']" to="/login">
          login
        </v-btn>
        <v-btn class="ml-2" outlined exact :class="['hidden-sm-and-down']" to="/signup">
          Sign Up
        </v-btn>
      </div>
    </v-app-bar>
    <v-navigation-drawer v-model="sidebar" absolute disable-resize-watcher>
      <v-list>
        <v-list-item>
          <img class="logo" src="/klik_logo.png" alt="" />
          <v-list-item-content>{{ appTitle }}</v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click.stop="sidebar = !sidebar">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-list-item v-for="(item, index) in menuItems" :key="index" :to="{ name: item.link }" exact>
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>{{ item.title }}</v-list-item-content>
        </v-list-item>

        <v-list-item v-if="isTokenSet" @click="userLogout">
          <v-list-item-action>
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            {{ $t('menuItems.LOGOUT') }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ResizeText from 'vue-resize-text'
import LocaleChanger from "./LocaleChanger.vue";

export default {
  name: 'Toolbar',
  metaInfo() {
    return {
      title: this.$store.getters.appTitle,
      meta: [
        { name: 'msapplication-TileColor', content: '#ffc40d' },
        { name: 'theme-color', content: '#ffffff' },
        {
          name: 'apple-mobile-web-app-title',
          content: this.$store.getters.appTitle
        },
        { name: 'application-name', content: this.$store.getters.appTitle }
      ],
      link: [
        {
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: '/apple-touch-icon.png'
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: '/favicon-32x32.png'
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          href: '/favicon-16x16.png'
        },
        { rel: 'manifest', href: '/site.webmanifest' },
        { rel: 'mask-icon', color: '#5bbad5', href: '/safari-pinned-tab.svg' },
        { rel: 'favicon', href: '/favicon.ico' }
      ]
    }
  },
  directives: {
    ResizeText
  },
  components: {
    LocaleChanger,
  },
  data() {
    return {
      isDark: false,
      sidebar: false
    }
  },
  computed: {
    ...mapGetters(['appTitle', 'isTokenSet', 'user']),
    admin() {
      return this.user !== null ? this.user.role === 'lord' : false
    },
    menuItems() {
      if (this.isTokenSet) {
        return []
      }
      return [
        {
          title: this.$t('menuItems.LOGIN'),
          link: 'login'
        },
        {
          title: this.$t('menuItems.SIGNUP'),
          link: 'signup'
        }
      ]
    }
  },
  methods: {
    userLogout() {
      this.$store.dispatch('userLogout')
    }
  }
}
</script>
<style scoped>
.logo {
  height: 32px;
  width: 32px;
  display: inline-block;
  margin-right: 10px;
}

.home {
  line-height: normal;
  vertical-align: middle;
  display: inline-block;
}
</style>
import * as types from '@/store/mutation-types'
import i18n from '@/plugins/i18n'
import * as Validator from 'vee-validate'

const getters = {
  getTab: (state) => state.tab,
  actionType: (state) => state.actionType
}

const actions = {
  setTab({ commit }, payload) {
    // commit(types.SUCCESS, null)
    // commit(types.ERROR, null)
    commit(types.SET_TAB, payload)
  },
  actionType({ commit }, payload) {
    // commit(types.SUCCESS, null)
    // commit(types.ERROR, null)
    commit(types.ACTION_TYPE, payload)
  }
}

const mutations = {
  [types.SET_TAB](state, value) {
    state.tab = value
  },
  [types.ACTION_TYPE](state, value) {
    state.actionType = value
  },
}

const state = {
  tab: 0,
  actionType: null,
  animeDir: null,
  animeSpeed: 100,
  animeBounce: null,
  fire: false,
  fireDir: null,
  fireSpeed: 100,
  fireBounce: null,
  fireSound: null,
  fireReplace: null,
  activeObject: null,
  sensorDrop: false,
  sensorAction: null,
  soundsrc: null,
  soundDrop: false,
  soundPayload: null,
  imageDrop: false,
  imageSrc: null,
  forgeData: {},
  drawing: false,
  addStage: 1,
  stager: false,
  stageUp: 0,
  stageHeight: "80vh",
  stageLastUp: 2,
  category: null,
  codex: false,
  frame: null,
  three: false,
  gameObjectTop: null,
  gameObjectLeft: null,
  sensorLinking: false,
  sensorLink: [],
  sensorTarget: [],
  sensorAllTargets: [],
  mode: "edit",
  oneMatter: null,
  multiTargets: [],
  multiSensors: [],
  allGallery: [],
  elementGallary: [],
  sceneGallary: [],
  musicGallary: [],
  sfxGallary: [],
  cardMutation: false,
  soundStop: false,
  soundPlay: false,
  soundData: null,
  mecha: "ClickScreen",
  code: "input",
  cardMenu: false,
  stageCardHeight: "80vh",
  cardHeight: "80vh",
  classAction: {
    timer: false,
    input: false,
    sensor: false
  },
  classEdit: {
    timer: false,
    input: false,
    sensor: false,
  },
  cardEdit: {
    rail: false,
  },
  // codeMenu: false,
  cardsMenu: false,
  cardCount: "0",
  modeMenu: false,
  selectOneMenu: false,
  changeMenu: false,
  changeMenu2: false,
  orderMenu: false,
  sensorMenu: false,
  actionProceed: false,
  timerMenu: false,
  swticherMenu: false,
  settingMenu: false,
  objectSettingMenu: false,
  objectLayerMenu: false,
  requestedMenu: null,
  currentMenu: null,
  timerSetting: false,
  timeoutSetting: false,
  gravitySetting: false,
  edgeSetting: false,
  trackSetting: false,
  zoomSetting: false,
  removeCameraTracker: false,
  applySwitcher: false,
  vanishOps: false,
  cloneOps: false,
  frontOps: false,
  backOps: false,
  orderOps: false,
  uploadMenu: false,
  sceneUploadMenu: false,
  musicUploadMenu: false,
  sfxUploadMenu: false,
  elementFilter: [],
  elementSearch: false,
  elementChip: "Character",
  elementMore: false,
  sceneFilter: [],
  sceneSearch: false,
  sceneChip: "New",
  sceneMore: false,
  musicFilter: [],
  musicSearch: false,
  musicChip: "New",
  musicMore: false,
  sfxFilter: [],
  sfxSearch: false,
  sfxChip: "New",
  sfxMore: false,
  activeTab: null,
  loadUploadMore: false,
  loadNextUpload: false,
  changeData: null,
  frameLoop: true,
  clickMenu: false,
  clickType: "down",
  sensorType: "touch",
  flipX: false,
  flipY: false,
  frameRate: 10,
  activeFrameRate: 10,
  activeFrameLoop: true,
  activeFlipX: false,
  activeFlipY: false,
  sensorModeSwitch: false,
  orderCards: [],
  layerObjects: [],
  cardSwitcherMenu: false,
  matterSwitcherMenu: false,
  cardStage :1,
  settingsMenu : false,
  openFileMenu : false,
  openFileData : null,
  soundRemove: false,
  soundDropListID : null,
  changeOpenFile: false,
  elementLoadMore: false,
  sceneLoadMore: false,
  musicLoadMore: false,
  sfxLoadMore: false,
  sfxMenuUpload : false,
  IDEStatus : false,
  // mecha: "Click"
}

export default {
  state,
  getters,
  actions,
  mutations
}

export default [
    {
      path: '/lab/:id',
      name: 'Lab',
      meta: {
        requiresAuth: true
      },
      component: () =>
        import(/* webpackChunkName: "test" */ '@/pages/Lab/index.vue')
    },
    {
      path: '/lab/profile/:id',
      name: 'Profile',
      meta: {
        requiresAuth: true
      },
      component: () =>
        import(/* webpackChunkName: "test" */ '@/pages/Lab/index.vue')
    }
  ]
import Vue from 'vue'
import '@/plugins/axios'
import vuetify from '@/plugins/vuetify'
import '@/plugins/veevalidate'
import '@/plugins/common'
import '@/plugins/googleAnalytics'
import i18n from '@/plugins/i18n'
import App from '@/App.vue'
import router from '@/router'
import { store } from '@/store'
import VuetifyConfirm from 'vuetify-confirm'
import posthogPlugin from "./plugins/posthog"; // import the plugin
import Particles from "vue2-particles";
import anime from 'animejs/lib/anime.min.js';

Vue.prototype.$anime = anime;

// import socket from './plugins/socket'

// Vue.prototype.$socket = socket


// import Swiper from 'swiper';
// import 'swiper/css';


Vue.config.productionTip = false
Vue.use(VuetifyConfirm, { vuetify })
// Vue.use(VueAnime);
Vue.use(posthogPlugin); // install the plugin, before new Vue()
Vue.use(Particles);

const app = new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App),
  created() {
    store.dispatch('setLocale', store.getters.locale)
    if (store.getters.isTokenSet) {
      store.dispatch('autoLogin')
    }
  }

}).$mount('#app')

if (window.Cypress) {
  // Only available during E2E tests
  window.app = app
}

export default [
    {
      path: '/plan',
      name: 'Plan',
      meta: {
        requiresAuth: true
      },
      component: () =>
        import(/* webpackChunkName: "test" */ '@/pages/Plan/index.vue')
    }
  ]
<template>
    <div>
        <v-bottom-sheet v-model="engineSheet" inset>
            <v-sheet class="d-flex flex-column" height="80vh">
                <v-btn class="mt-6" text color="error" @click="engineSheet = !engineSheet">
                    close
                </v-btn>
                <h2 class="mt-5" align="center" justify="center">Pick One Game Mechanic</h2>
                <v-container class="mt-3">
                    <v-row class="ma-0 pa-0" align="center" justify="center">
                        <v-col cols="5" align="center" justify="center">
                            <v-card @click="clicker" outlined align="center" justify="center">
                                <lottie class="instra" style="pointer-events: none !important;" :options="click"
                                    :height="80" :width="80" />
                                <!-- <v-card-title align="center" justify="center">Clicks</v-card-title> -->
                                <v-card-subtitle><span class="font-weight-bold">Click</span> On Objects and
                                    Buttons</v-card-subtitle>
                            </v-card>
                        </v-col>
                        <v-col cols="5" align="center" justify="center">
                            <v-card disabled @click="clicker" outlined align="center" justify="center">
                                <lottie class="instra" style="pointer-events: none !important;" :options="joystick"
                                    :height="80" :width="80" />
                                <!-- <v-card-title align="center" justify="center">Clicks</v-card-title> -->
                                <v-card-subtitle><span class="font-weight-bold">Joystick</span> Object
                                    Movement</v-card-subtitle>
                            </v-card>
                        </v-col>

                    </v-row>
                    <v-row class="ma-0 pa-0 mt-3" align="center" justify="center">
                        <v-col class="mt-0 pt-0" cols="5" align="center" justify="center">
                            <v-card disabled @click="clicker" outlined align="center" justify="center">
                                <lottie class="instra" style="pointer-events: none !important;" :options="drag"
                                    :height="80" :width="80" />
                                <!-- <v-card-title align="center" justify="center">Clicks</v-card-title> -->
                                <v-card-subtitle><span class="font-weight-bold">Drag</span> Objects
                                    Around</v-card-subtitle>
                            </v-card>
                        </v-col>
                        <v-col class="mt-0 pt-0" cols="5" align="center" justify="center">
                            <v-card disabled @click="clicker" outlined align="center" justify="center">
                                <lottie class="instra" style="pointer-events: none !important;" :options="hold"
                                    :height="80" :width="80" />
                                <!-- <v-card-title align="center" justify="center">Clicks</v-card-title> -->
                                <v-card-subtitle><span class="font-weight-bold">Hold</span> and Timed
                                    Action</v-card-subtitle>
                            </v-card>
                        </v-col>

                    </v-row>
                </v-container>
                <!-- <v-row align="center" justify="center" class="mt-8">
                        <v-col>
                            <v-row align="center" justify="center">
                                <p>Your Game Mechanic:</p>
                            </v-row>
                            <v-row align="center" justify="center">
                                <v-btn-toggle v-model="engineMecha" color="primary">
                                    <v-col align="center" justify="center">
                                        <v-row align="center" justify="center">
                                            <v-btn value="clicks" x-large color="primary" outlined><v-icon x-large
                                                    class="mr-3">mdi-gesture-double-tap</v-icon>
                                                Clicks</v-btn>
                                        </v-row>
                                        <v-row align="center" justify="center">
                                            <v-btn value="joystick" class="mt-3" x-large color="primary" outlined><v-icon
                                                    x-large class="mr-3">mdi-camera-control</v-icon>
                                                Joystick</v-btn>
                                        </v-row>
                                    </v-col>
                                </v-btn-toggle>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row align="center" justify="center" class="mt-8">
                        <v-col>
                            <v-row align="center" justify="center">
                                <p>Your Game Art:</p>
                            </v-row>
                            <v-row align="center" justify="center">
                                <v-btn-toggle v-model="engineArt" color="primary">
                                    <v-col align="center" justify="center">
                                        <v-row align="center" justify="center">
                                            <v-btn value="clicks" x-large color="primary" outlined><v-icon x-large
                                                    class="mr-3">mdi-emoticon-cool-outline</v-icon>
                                                klik Art</v-btn>
                                        </v-row>
                                        <v-row align="center" justify="center">
                                            <v-btn value="joystick" class="mt-3" x-large color="primary" outlined><v-icon
                                                    x-large class="mr-3">mdi-google-downasaur</v-icon>
                                                Pixel Art</v-btn>
                                        </v-row>
                                    </v-col>
                                </v-btn-toggle>
                            </v-row>
                        </v-col>
                    </v-row> -->

                <!-- <v-btn to="/lab/new" class="mt-8 mx-8" color="primary" large :disabled="!engineArt || !engineMecha">Start
                    Creating!</v-btn> -->
            </v-sheet>
        </v-bottom-sheet>

        <div class="mbottom">
            <div class="box" v-if="!$store.state.exp.exploreMode">
                <div class="time timer paused"></div>
            </div>
            <v-bottom-navigation v-model="bottomNav" id="bottNav" color="primary" dark background-color="black">

                <v-btn v-if="$store.state.exp.exploreMode" value="home" plain :to="{ name: 'Test' }">
                    <v-icon :color="bottomNav === 'home' ? 'primary' : 'grey lighten-5'" size="32">{{ bottomNav ===
                        "home" ?
                        'mdi-home' : 'mdi-home-outline' }}</v-icon>
                </v-btn>
                <v-btn v-else plain @click="$store.state.exp.stop = true">
                    <v-icon size="32">mdi-backspace</v-icon>
                </v-btn>
                <v-btn @click="$store.state.exp.stop = true" value="explore" plain :to="{ name: 'Explore' }">
                    <v-icon :color="bottomNav === 'explore' ? 'primary' : 'grey lighten-5'" size="32">{{ bottomNav ===
                        "explore" ? 'mdi-compass' : 'mdi-compass-outline' }}</v-icon>
                </v-btn>
                <v-btn value="create" plain @click="clicker(); $store.state.exp.stop = true">
                    <v-icon :color="bottomNav === 'create' ? 'primary' : 'grey lighten-5'" size="32">{{ bottomNav ===
                        "create" ? 'mdi-plus-circle' : 'mdi-plus-circle-outline'
                        }}</v-icon>
                </v-btn>
                <v-btn @click="$store.state.exp.stop = true" value="inbox" plain :to="{ name: 'Inbox' }">
                    <v-icon :color="bottomNav === 'inbox' ? 'primary' : 'grey lighten-5'"
                        v-if="!$store.state.profile.noti" size="32">{{ bottomNav === "inbox" ? 'mdi-bell' :
                            'mdi-bell-outline' }}</v-icon>

                    <v-badge v-else overlap color="red" :content=$store.state.profile.noti>
                        <v-icon :color="bottomNav === 'inbox' ? 'primary' : 'grey lighten-5'" size="32">{{ bottomNav ===
                            "inbox" ? 'mdi-bell' : 'mdi-bell-outline' }}</v-icon>
                    </v-badge>

                </v-btn>
                <v-btn @click="$store.state.exp.stop = true" value="profile" plain :to="{ name: 'profile' }">
                    <v-icon :color="bottomNav === 'profile' ? 'primary' : 'grey lighten-5'" size="32">{{ bottomNav ===
                        "profile" ? 'mdi-account' : 'mdi-account-outline' }}</v-icon>
                </v-btn>
            </v-bottom-navigation>
            <!-- <v-bottom-navigation v-else v-model="bottomNav" id="bottNav" color="grey" dark background-color="#191E22">

                <v-btn value="home" plain>
                    <v-icon size="32">mdi-backspace</v-icon>
                </v-btn>
                <v-btn value="explore" plain>
                    <v-icon size="32">mdi-information-outline</v-icon>
                </v-btn>
                <v-btn value="create" plain>
                    <v-icon size="32">mdi-arrow-up-bold-circle-outline</v-icon>
                </v-btn>
                <v-btn value="inbox" plain>
                    <v-icon size="32">mdi-arrow-down-bold-circle-outline</v-icon>
                </v-btn>
                <v-btn value="profile" plain>
                    <v-icon size="32">mdi-restart</v-icon>
                </v-btn>
            </v-bottom-navigation> -->
        </div>
    </div>
</template>

<script>
import * as targe from "/public/targe.json";
import * as click from "/public/click.json";
import * as hold from "/public/hold.json";
import * as drag from "/public/drag.json";
import * as joystick from "/public/joystick.json";

export default {
    data() {
        return {
            engineSheet: false,
            bottomNav: null,
            scoreScene: null,
            engineMecha: null,
            engineArt: null,
            targe: {
                animationData: targe
            },
            click: {
                animationData: click
            },
            drag: {
                animationData: drag
            },
            hold: {
                animationData: hold
            },
            joystick: {
                animationData: joystick
            },
        }
    },
    methods: {
        gameRunning() {
            if (!this.$store.state.exp.stop) {
                console.log("STOP")
                this.$store.state.exp.stop = true
            }
        },
        clicker() {
            console.log("click")
            this.$router.push({ path: '/lab/new' });
        }
    }
}
</script>

<style scoped>
.box {
    /* border-left: 1px solid #000;
    border-right: 1px solid #000; */
    height: 3px;
    width: 100%;
}


.time {
    margin: 0;
    padding: 0;
}

.timer {
    background: #A000FF;
    height: 100%;
    animation-name: time;
    animation-duration: 20s;
    animation-timing-function: linear;
}

.paused {
    animation-play-state: paused;
}

@keyframes time {
    from {
        width: 100%;
    }

    to {
        width: 0%;
    }
}

.mbottom {
    position: fixed;
    bottom: 0%;
    /* height: 100%; */
    left: 0%;
    z-index: 99;
    width: 100%;
}
</style>
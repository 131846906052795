export default [
  {
    path: '/',
    name: 'landing',
    component: () =>
      import(/* webpackChunkName: "landing" */ '@/pages/Landing/index.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () =>
      import(/* webpackChunkName: "landing" */ '@/pages/About/index.vue')
  },
  {
    path: '/hiring',
    name: 'Hiring',
    component: () =>
      import(/* webpackChunkName: "landing" */ '@/pages/Hiring/index.vue')
  }
]

import * as types from '@/store/mutation-types'
import i18n from '@/plugins/i18n'
import * as Validator from 'vee-validate'
import Vue from 'vue'
import Vuex from 'vuex'

const getters = {
  card: (state) => state.card,
}

const actions = {
  timeCardAction({ commit }, payload) {
    // commit(types.SUCCESS, null)
    // commit(types.ERROR, null)

    commit(types.TIME_CARD, payload)
  },

}

const mutations = {
  [types.TIME_CARD](state, value) {
    console.log("VALUE ::: ", value)
    state.card = value
  },

}

const state = {
  card: {
    id: null,
    type: null,
    action: null,
    target: null,
    link: [],
    meta: {},
    shot: null,
    sfx: null,
    vfx: null,
    fire: false,
    order: null,
  },
  type: null,
  // CardType: null,
  CardAction: null,
  timeRailCard: {
    timer: null,
    velocity: null,
    ease: null,
    loop: null,
    rotation: null,
  },
  endTimer: 20,
  endResult: "lose",
  timeEndCard: {
    timer: 20,
    result: "lose"
  },
  endEdit: false,
  sensorEndCard: {
    result: "win"
  },
  sensorSelecting: false,
  changeID: null,
  changeData: null,
  allCards: [],
  cardEditing: false,
  cardChange: false,
  changeAnimeTarget: null,
  timerData: null,
  inputData: null,
  sensorEditSelecting: false,
  cardMaker: false,
  objectSheet: false,
  AISDSheet: false,
  AISheet: false,
  changeSheet: false,
  musicSheet: false,
  SFXSheet: false,
  changeTarget: null,
  cardShift: false,
  savedObject: null,
  frame: null,
  cardDelete: false,
  selecting: false,
  selectionEdit: false,
  gravityPoint: null,
  gravityForce: { x: 0, y: 0 },
  edge: false,
  drawExp: false,
  // camera: {
  //   target: null,
  //   shot: null,
  //   zoom: 100
  // },
  camTarget: null,
  camShot: null,
  camZoom: 100,
  mode: "edit",
  cameraOps: false,
  remoteOps: false,
  endOps: false,
  moveOps: false,
  followOps: false,
  portal: false,
  rope: null,
  counter: 1,
  launch: false,
  musicTitle: null,
  musicSrc: null,
  debugSetting : false
}

export default {
  state,
  getters,
  actions,
  mutations
}

export default [
    {
        path: '/analytic',
        name: 'analytic',
        meta: {
            requiresAuth: true
        },
        component: () =>
            import(/* webpackChunkName: "home" */ '@/pages/Analytic/index.vue')
    },
]
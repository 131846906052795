import * as types from '@/store/mutation-types'
import i18n from '@/plugins/i18n'
import * as Validator from 'vee-validate'
import Vue from 'vue'
import Vuex from 'vuex'

const getters = {
  // type: (state) => state.type,
}

const actions = {
 

}

const mutations = {
  

}

const state = {
  removeVariantMenu :false,
  removeVariantData :null,
  removeFileMenu :false,
  removeFileData :null,
}

export default {
  state,
  getters,
  actions,
  mutations
}
